import type { ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';
import uri from 'uri-tag';
import { FeedView } from './feed.view.js';
import type { FeedProps } from './types.js';

const controller =
  (View: typeof FeedView): ComponentType<FeedProps> =>
  (props) => {
    const { tracks, loading } = props;
    const navigate = useNavigate();

    return View({
      loading,
      onItemClick: (track) => {
        navigate(uri`/track/${track._id}`);
      },
      tracks,
    });
  };

export default controller(FeedView);
