import { Box, ButtonBase, Skeleton, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import { FeedItemTrackView } from './item/track.js';
import type { FeedViewProps } from './types.js';

export const FeedView: FC<FeedViewProps> = ({ loading, ...props }) =>
  loading ? (
    <Skeleton variant="rounded" height={60} width="100%" />
  ) : (
    <FeedStack {...props} />
  );

const FeedStack: FC<Omit<FeedViewProps, 'loading'>> = ({
  onItemClick,
  tracks,
}) => (
  <Box display="flex" flex={1}>
    {tracks?.items.length ? (
      <Stack direction="column" spacing={2} width="100%" alignItems="stretch">
        {tracks?.items.map((track) => (
          <ButtonBase
            key={track._id}
            sx={{ justifyContent: 'stretch', textAlign: 'start' }}
            onClick={() => onItemClick(track)}
          >
            <FeedItemTrackView track={track} />
          </ButtonBase>
        ))}
      </Stack>
    ) : (
      <Stack direction="column" spacing={2} width="100%" alignItems="stretch">
        <Typography variant="h6">
          To get started, upload or import your first track.
        </Typography>
        <Typography variant="body1">
          Tracks can be either imported from Strava Activities (easiest), or
          uploaded as FIT, TCX or GPX files. These files can be exported from
          the Garmin Connect website (not app), or various other tracking apps.
        </Typography>
        <Typography variant="body1">
          Once a track is imported, it will automatically search for a matching
          event based on time and location. If an event is found, you will just
          need to select the course and then you will be able to compare to
          others who recorded a track for the same course.
        </Typography>
      </Stack>
    )}
  </Box>
);
