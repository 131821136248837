import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import type { Track } from 'core';
import type { FC } from 'react';
import {
  InfoFieldStack,
  InfoFieldView,
} from '../../../components/info-field/info-field.js';
import { OriginalFormat } from '../../../components/original-format/view.js';
import {
  formatDate,
  formatMeters,
  formatSeconds,
  formatSecondsPerMeter,
} from '../../../utils/format.js';

export const FeedItemTrackView: FC<{
  track: Track.Type;
  onReprocessClicked?: () => void;
}> = ({ track, onReprocessClicked }) => (
  <Card onClick={onReprocessClicked} sx={{ display: 'flex', flex: 1 }}>
    <CardContent>
      <Stack spacing={1}>
        <Box display="flex" flex={1} justifyContent="space-between">
          <Typography variant="h3">{track.title}</Typography>
          <OriginalFormat format={track.originalFormat} />
        </Box>
        <InfoFieldStack>
          <InfoFieldView label="Date">
            {formatDate(track.start.when)}
          </InfoFieldView>
          <Divider orientation="vertical" sx={{ height: '2rem' }} />
          <InfoFieldView label="Duration">
            {formatSeconds(track.stats.seconds)}
          </InfoFieldView>
          {track.stats.meters ? (
            <>
              <Divider orientation="vertical" sx={{ height: '2rem' }} />
              <InfoFieldView label="Distance">
                {formatMeters(track.stats.meters) ?? '-'}
              </InfoFieldView>
              <Divider orientation="vertical" sx={{ height: '2rem' }} />
              <InfoFieldView label="Pace">
                {formatSecondsPerMeter(
                  track.stats.meters
                    ? track.stats.seconds / track.stats.meters
                    : undefined,
                ) ?? '-'}
              </InfoFieldView>
            </>
          ) : undefined}
        </InfoFieldStack>
      </Stack>
    </CardContent>
  </Card>
);
